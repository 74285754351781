import { lazy } from "react";
import AuthGuard from "../utils/guards/AuthGuard";
import Loadable from "../components/general/Loadable";
import MainLayout from "../components/general/MainLayout";
import FeatureRouter from "../components/FeatureRouter/FeatureRouter";
import { OAuthPage } from "../pages/OAuth";
import UserGuide from "../pages/UserGuide";
import StatusesGlossary from "../pages/StatusesGlossary";
import Logout from "../pages/logout";

const Leads = Loadable(lazy(() => import("../pages/Leads")));
const Lead = Loadable(lazy(() => import("../pages/Lead")));
const AddLead = Loadable(lazy(() => import("../pages/AddLead")));
const Sales = Loadable(lazy(() => import("../pages/Sales")));
const Commissions = Loadable(lazy(() => import("../pages/Commissions")));
const ListingDashboard = Loadable(lazy(() => import("../pages/ListingDashboard")));
const LeadIcons = Loadable(lazy(() => import("../pages/LeadIcons")));
const ResetPassword = Loadable(
  lazy(() => import("../components/authentication/ResetPassword"))
);
const NawyInventory = Loadable(lazy(() => import("../pages/NawyInventory")));

const CommonRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <Leads />,
    },
    {
      path: "/leads",
      element: <Leads />,
    },
    {
      path: "/nextActions",
      element: <Leads alertPage="manual" />,
    },
    {
      path: "/leads/:id",
      element: <Lead />,
    },
    {
      path: "/leads/new",
      element: <AddLead />,
    },
    {
      path: "/sales",
      element: <Sales />,
    },
    {
      path: "/commissions",
      element: (
        <FeatureRouter featureKey={"commissions"} element={<Commissions />} />
      ),
    },
    {
      path: "/UserGuide",
      element: (
        <FeatureRouter featureKey={"UserGuide"} element={<UserGuide />} />
      ),
    },
    {
      path: "/StatusesGlossary",
      element: (
        <FeatureRouter
          featureKey={"StatusesGlossary"}
          element={<StatusesGlossary />}
        />
      ),
    },
    {
      path: "/changePassword",
      element: <ResetPassword />,
    },
    {
      path: "/nawy-inventory",
      element: <NawyInventory />,
    },
    {
      path: "/OAuth",
      element: <OAuthPage />,
    },
    {
      path: "/listing-dashboard",
      element: <ListingDashboard />,
    },
    {
      path: "/lead-icons",
      element: <LeadIcons />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ],
};

export default CommonRoutes;
