import React, { createContext, useEffect, useReducer } from "react";
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import accountReducer from "../store/accountReducer";
import axios from "../utils/axios";
import Loader from "../components/general/Loader";
import Cookies from "universal-cookie/es6";
import {
  PushMessagingRegister,
  PushMessagingUnregister,
} from "../serviceWorkerRegistration";
import {
  handleNewCrmRedirection,
  parseCrmCustomGroups,
} from "../helpers/GeneralHelper";
import { useLocation } from "react-router-dom";

const clientId = process.env.REACT_APP_AWS_CLIENT_ID;

const cookies = new Cookies();

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  isBetaUser: false,
  isAlphaUser: false,
};

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_POOL_ID,
  ClientId: clientId,
});

const AuthContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const location = useLocation();

  useEffect(() => {
    const init = async () => {
      try {
        const idToken = getTokenByKey("idToken");
        if (idToken) {
          const user = userPool.getCurrentUser();
          if (!user) {
            deleteSession();
          } else {
            user.getSession((error, session) => {
              if (session) {
                setUserState(session);
              } else {
                deleteSession();
              }
            });
          }
        } else {
          logout();
        }
      } catch (err) {
        logout();
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTokenByKey = (tokenKey) => {
    const usernameString = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
    const username = localStorage.getItem(usernameString);
    if (username) {
      const tokenString = `CognitoIdentityServiceProvider.${clientId}.${username}.${tokenKey}`;
      return localStorage.getItem(tokenString);
    }
  };

  const setSession = (serviceToken, expTime) => {
    localStorage.setItem("expTime", expTime);
    cookies.set("expTime", expTime, {
      path: "/",
      domain: process.env.REACT_APP_COOKIES_SHARED_DOMAIN,
      secure: true,
      sameSite: "Lax",
    });
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  };

  const deleteSession = () => {
    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
    dispatch({ type: "LOGOUT" });
  };

  const setUserState = async (session) => {
    const id_token = session.getIdToken();
    const payload = id_token.payload;
    setSession(session.getIdToken().getJwtToken(), payload.exp);
    PushMessagingRegister(session.accessToken.payload.username);
    const parsedGroups = parseCrmCustomGroups(payload["cognito:groups"]);
    localStorage.setItem("userRoles", parsedGroups);
    dispatch({
      type: "LOGIN",
      payload: {
        isLoggedIn: true,
        isBetaUser: parsedGroups?.includes("new_crm_beta_user"),
        isAlphaUser: parsedGroups?.includes("new_crm_alpha_user"),
        user: {
          cognitoId: payload.sub,
          username: payload["cognito:username"],
          email: payload.email,
          name: payload.name,
          phone: payload.phone,
          groups: parsedGroups,
          crmRoles: parsedGroups,
          isSupervisor: payload.isSupervisor === "false" ? false : true,
          crmId: payload.crmId,
        },
      },
    });
  };

  const refreshUserState = () => {
    const user = userPool.getCurrentUser();
    const storageRefreshToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.${user.username}.refreshToken`;
    const refreshToken = localStorage.getItem(storageRefreshToken);
    if (refreshToken && user) {
      return new Promise((resolve, reject) => {
        user.refreshSession(
          new CognitoRefreshToken({ RefreshToken: refreshToken }),
          (err, result) => {
            if (result) {
              setUserState(result);
              resolve(result);
            } else {
              deleteSession();
              reject();
            }
          }
        );
      });
    }
    throw new Error("Session Ended, Please Login Again");
  };

  const login = async (email, password) => {
    const usr = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authData = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      usr.authenticateUser(authData, {
        onSuccess: (session) => {
          setUserState(session);
          resolve();
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          dispatch({
            type: "PASSWORD_CHALLENGE",
            payload: {
              isLoggedIn: false,
              user: {
                email: email,
                password: password,
              },
            },
          });
          resolve({ data: { challenge: "PASSWORD_CHALLENGE" } });
        },
        onFailure: (_err) => {
          reject(_err);
        },
      });
    });
  };

  const completePasswordChallenge = async (newPassword) => {
    const usr = new CognitoUser({
      Username: state.user.email,
      Pool: userPool,
    });

    const authData = new AuthenticationDetails({
      Username: state.user.email,
      Password: state.user.password,
    });

    return new Promise((resolve, reject) => {
      usr.authenticateUser(authData, {
        onSuccess: (session) => {
          setUserState(session);
          resolve();
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          usr.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (session) => {
                setUserState(session);
                resolve();
              },
              onFailure: (_err) => {
                reject(_err);
              },
            }
          );
        },
        onFailure: (_err) => {
          reject(_err);
        },
      });
    });
  };

  const sendVerificationCode = async (email) => {
    const usr = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    return new Promise((resolve, reject) => {
      usr.forgotPassword({
        onSuccess: () => {
          dispatch({
            type: "VERIFICATION_CODE",
            payload: {
              isLoggedIn: false,
              user: {
                email,
              },
            },
          });
          resolve();
        },
        onFailure: (_err) => {
          reject(_err);
        },
      });
    });
  };

  const resendVerificationCode = (username) => {
    const usr = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      usr.resendConfirmationCode((err, result) => {
        if (result) {
          resolve();
        }
        if (err) {
          reject();
        }
      });
    });
  };

  const confirmSignUp = (username, token) => {
    const usr = new CognitoUser({
      Username: username,
      Pool: userPool,
    });
    return new Promise((resolve, reject) => {
      usr.confirmRegistration(token, true, (err, result) => {
        if (result) {
          resolve();
        }
        if (err) {
          reject(err);
        }
      });
    });
  };

  const confirmPasswordReset = (username, verification_code, new_password) => {
    const usr = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      usr.confirmPassword(verification_code, new_password, {
        onSuccess: () => {
          dispatch({
            type: "PASSWORD_CONFIRMED",
            payload: {
              isLoggedIn: false,
              user: null,
            },
          });
          resolve();
        },
        onFailure: (_err) => {
          reject(_err);
        },
      });
    });
  };

  const changePassword = async (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((err, res) => {
          if (res) {
            user.changePassword(oldPassword, newPassword, (error, result) => {
              if (error) {
                reject(error);
              }
              if (result) {
                resolve();
              }
            });
          }
        });
      }
    });

  const authorize = async (authorizationCode) => {
    const body = `grant_type=authorization_code&client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&code=${authorizationCode}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`;
    const response = await axios.post(
      `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`,
      body,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    createUserSession(response.data);
  };

  const impersonate = async (username, password) => {
    const response = await axios.post(
      `${process.env.REACT_APP_CRM_API}/impersonate`,
      {
        username: username,
        password: password,
      },
      {
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
      }
    );
    localStorage.clear();
    createUserSession(response.data.authentication_result);
  };

  const createUserSession = (tokens) => {
    const accessToken = new CognitoAccessToken({
      AccessToken: tokens.access_token,
    });
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: tokens.refresh_token,
    });
    const idToken = new CognitoIdToken({
      IdToken: tokens.id_token,
    });
    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    };
    const session = new CognitoUserSession(sessionData);

    var userData = {
      Username: accessToken.payload.username,
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);

    cognitoUser.setSignInUserSession(session);

    setUserState(session);

    cookies.set(`${clientId}.idToken`, idToken.getJwtToken(), {
      path: "/",
      domain: process.env.REACT_APP_COOKIES_SHARED_DOMAIN,
      secure: true,
      sameSite: "Lax",
    });
    cookies.set(`${clientId}.refreshToken`, refreshToken.getToken(), {
      path: "/",
      domain: process.env.REACT_APP_COOKIES_SHARED_DOMAIN,
      secure: true,
      sameSite: "Lax",
    });
  };

  const logout = async () => {
    const loggedInUser = userPool.getCurrentUser();
    if (loggedInUser) {
      cookies.remove(`${process.env.REACT_APP_AWS_CLIENT_ID}.idToken`, {
        path: "/",
        domain: process.env.REACT_APP_COOKIES_SHARED_DOMAIN,
      });
      cookies.remove(`${process.env.REACT_APP_AWS_CLIENT_ID}.refreshToken`, {
        path: "/",
        domain: process.env.REACT_APP_COOKIES_SHARED_DOMAIN,
      });
      let userCognitoId = window.localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.LastAuthUser`
      );
      PushMessagingUnregister(userCognitoId);
      loggedInUser.signOut();
    }
    deleteSession();
  };

  useEffect(() => {
    const pathname = location?.pathname;
    const leadId = pathname?.match(/\/leads\/(\d+)/)?.[1];

    if (
      process.env.REACT_APP_COMPANY_NAME === "nawy" &&
      (location?.pathname === "/" || location?.pathname.includes("/leads")) &&
      state.isLoggedIn
    ) {
      handleNewCrmRedirection("beta", pathname);
    }

    if (
      state.isLoggedIn &&
      leadId &&
      process.env.REACT_APP_COMPANY_NAME === "nawy"
    ) {
      window.location.href = `${process.env.REACT_APP_NEW_CRM_URL}/leads/${leadId}`;
    }

    if (
      !state.isLoggedIn ||
      !(state.isBetaUser || state.isAlphaUser) ||
      (!(location?.pathname === "/" || location?.pathname.includes("/leads")) &&
        !state.isAlphaUser)
    )
      return;

    const role = state.isAlphaUser ? "alpha" : "beta";

    handleNewCrmRedirection(role, pathname);
  }, [state, location?.pathname]);

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        authorize,
        logout,
        sendVerificationCode,
        resendVerificationCode,
        confirmPasswordReset,
        confirmSignUp,
        changePassword,
        completePasswordChallenge,
        getTokenByKey,
        refreshUserState,
        impersonate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
