export const oldToNewPathsMap = new Map([
  ["/", "/"],
  ["/leads", "/"],
  ["/sales-agents-commisions", "/sales-commissions"],
  ["/sales", "/sales"],
  ["/calculator", "/nawy-now-calculator"],
  ["/nextActions", "/next-actions"],
  ["/listing-dashboard", "/listing-dashboard"],
  ["/agentsTree", "/agents-tree"],
  ["/search-by-comments", "/comments-search"],
  ["/listing-dashboard", "/listing-dashboard"],
  ["/sales", "/sales"],
  ["/lead-icons", "/lead-icons"],
  ["/settings/categories", "/categories"],
  ["/settings/pools", "/pools"],
  ["/settings/configurations", "/configurations"],
]);

export const redirectPaths = [
  "/",
  "/leads",
  "/nextActions",
  "/calculator",
  "/agentsTree",
  "/listing-dashboard",
  "/sales",
  "/sales-agents-commisions",
  "/search-by-comments",
  "/listing-dashboard",
  "/sales",
  "/lead-icons",
  "/settings/categories",
  "/settings/pools",
  "/settings/configurations",
];
